/* eslint-disable consistent-return */

import { DATA_LAYER_SEARCH_CLICK } from "Consts/dataLayer";
import { isDevelopment, isProduction } from "./utils";

export const setDataLayer = (dataObj, urlTarget) => {

	if (urlTarget === "GTM") {

		const dataLayer = window.dataLayer || [];

		if (typeof dataObj === "object" && dataObj !== null) {

			dataLayer.push(dataObj);

		}

	}

};

export const setDataLayerNew = () => {

	if (!isProduction && !isDevelopment) return null;

	if (typeof window === "undefined") return null;

	const dataLayer = window.dataLayer || [];

	if (typeof dataLayer.push !== "function") return null;

	return {
		search: (payload) => {

			if (!payload || typeof payload !== "object") return;

			const dataLayerObj = {
				event: payload.event || DATA_LAYER_SEARCH_CLICK,
				device: payload.device,
				is_login: payload.is_login,
				username: payload.username,
				...(payload.referral && { referral: payload.referral }),
				...(payload.keyword && { keyword: payload.keyword }),
				...(payload.section && { clickedsection: payload.section }),
				...(payload.viewAllSection && { clickedsection: payload.viewAllSection }),
				...(payload.section === "product" && { productname: payload.data }),
				...(payload.section === "brand" && { brandname: payload.data }),
				...(payload.section === "trending" && { keyword_suggestion: payload.data }),
				...(payload.section === "promotion" && { promotion_name: payload.data }),
				...(payload.section === "history" && { history_keyword: payload.data }),
				...(payload.section === "keyword_suggestion" && { keyword_suggestion: payload.data }),
			};

			dataLayer.push(dataLayerObj);

		},
		view: (payload) => {

			if (!payload || typeof payload !== "object") return;

			const dataLayerObj = {
				event: payload.event || DATA_LAYER_SEARCH_CLICK,
				device: payload.device,
				is_login: payload.is_login,
				username: payload.username,
				referral: payload.referral,
			};

			dataLayer.push(dataLayerObj);

		},
	};

};
